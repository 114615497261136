import {LockClosedIcon, XMarkIcon} from '@heroicons/react/20/solid'
import {AccessLevel} from '@myadbox/nebula-service-api'
import {HTMLAttributes, ReactNode} from 'react'
import {noop} from '../../utils/noop'
import Avatar from '../Avatar'
import Button from '../Button'
import Select from '../Select'

interface Props extends HTMLAttributes<HTMLElement> {
  name?: string
  email?: string
  expiration?: string
  img?: string
  onRemove?: () => void
  onRoleChange?: (e: unknown) => void
  onExpirationChange?: (e: unknown) => void
  inlineActions?: ReactNode
  userRoles?: UserRoleOption[]
  readOnly?: boolean
  avatarSize?: `lg` | `md` | `sm`
  private?: boolean
}

export type UserRole = AccessLevel

export interface UserRoleOption {
  value: UserRole
  label: string
  disabled?: boolean
  disabledText?: string
}

export const expirationTypes = [
  {
    value: `unrestricted`,
    label: `Unrestricted`,
  },
  {
    value: `scheduled`,
    label: `Scheduled`,
  },
]

const getOption = ({
  value,
  label,
  disabled = false,
  disabledText = ``,
}): ReactNode => (
  <Select.NativeOption key={value} value={value} disabled={disabled}>
    {disabled ? disabledText : label}
  </Select.NativeOption>
)

const styles = {
  '--grid-template-columns-var-1': `1fr minmax(auto, 50%)`,
}

const UserCard = ({
  name,
  email,
  role,
  expiration,
  img,
  onRemove,
  onRoleChange = noop,
  userRoles = [],
  onExpirationChange,
  inlineActions,
  readOnly,
  avatarSize,
  private: privateTeam,
}: Props) => {
  const placeholderValue =
    expiration !== `unrestricted` && expiration !== `scheduled`
      ? {value: expiration, label: expiration}
      : null

  return (
    <div
      className={`
        grid
        max-w-full
        grid-cols-[--grid-template-columns-var-1]
        items-center
        justify-between
        py-1.5
      `}
      style={styles}
    >
      <div
        className={`
          flex
          items-center
        `}
      >
        <div>
          <Avatar size={avatarSize} src={img} title={name} />
        </div>
        <div
          className={`
            mx-2
          `}
        >
          <div
            className={`
              text-ui-800
              flex
              w-full
              items-center
              gap-2
              text-sm
          `}
            title={name}
          >
            <span className={`truncate`}>{name}</span>
            {privateTeam && (
              <LockClosedIcon
                width="12"
                height="12"
                className={`text-ui-300`}
                data-testid="lock-icon"
              />
            )}
          </div>
          <div
            className={`
              w-full
              overflow-hidden
              truncate
              text-xs
              font-normal
            `}
            title={email}
          >
            {email}
          </div>
        </div>
      </div>
      <div
        className={`
          flex
          items-center
          justify-end
        `}
      >
        {inlineActions}
        {role && (
          <div
            style={{
              flexBasis: `12ch`,
              flexGrow: 0,
            }}
          >
            <Select.Native
              variants={[`inline`]}
              name="role"
              aria-label="role"
              onChange={onRoleChange}
              value={role}
              disabled={readOnly}
            >
              {userRoles.map(option => getOption(option))}
            </Select.Native>
          </div>
        )}
        {expiration && (
          <div
            style={{
              flexBasis: `12ch`,
              flexGrow: 0,
            }}
          >
            <Select.Native
              variants={[`inline`]}
              name="expiration"
              aria-label="expiration"
              onChange={onExpirationChange}
              value={expiration}
              disabled={readOnly}
            >
              {placeholderValue && (
                <Select.NativeOption value={placeholderValue.value}>
                  {placeholderValue.label}
                </Select.NativeOption>
              )}
              {expirationTypes.map(option => getOption(option))}
            </Select.Native>
          </div>
        )}
        {onRemove && !readOnly && (
          <Button
            size="sm"
            slim
            variant="text"
            title="remove"
            aria-label="remove"
            onClick={onRemove}
            type="button"
          >
            <XMarkIcon className={`text-ui-600`} width={14} height={14} />
          </Button>
        )}
      </div>
    </div>
  )
}

export default UserCard

import {PieDefaultColours} from '../Pie/Pie'
import {LegendLabel} from './LegendLabel'
import './recharts-overrides.css'

interface Props {
  header?: LegendSegment
  segments: LegendSegment[]
  columns?: 1 | 2
  spacer?: string
  colours?: string[]
}

export type LegendSegment = {
  value?: number | string
  value2?: number | string
  colour?: string
  label?: string
}

export const Legend = ({
  header,
  segments,
  columns = 2,
  spacer,
  colours,
}: Props) => {
  const palmGridColumnsClass = columns === 2 ? `sm:grid-cols-2` : ``
  return (
    <section
      className={`
        ${palmGridColumnsClass}
        mt-2
        grid
        w-full
        grid-cols-1
      `}
      style={{gridColumnGap: `1rem`}}
    >
      {header && (
        <h3
          className={`
              mb-2
              flex
              items-baseline
            `}
        >
          <LegendLabel item={header} labelVariant={`caption`} spacer={spacer} />
        </h3>
      )}
      <ul>
        {segments.map((segment, index) => {
          const styles = {
            '--grid-columns-var-1': index === 0 ? `1 / 1` : `auto`,
          }
          return (
            <li
              key={segment.label}
              className={`
              relative
              col-[--grid-columns-var-1]
              flex
              basis-[50%]
              items-center
          `}
              style={styles}
            >
              <LegendLabel item={segment} spacer={spacer}>
                <svg
                  viewBox="0 0 16 16"
                  role="presentation"
                  width={16}
                  height={16}
                  className={`-mt-px mr-2
 inline-block h-4 w-4`}
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="7"
                    stroke={
                      segment.colour ||
                      (colours && colours[index]) ||
                      PieDefaultColours[index]
                    }
                    strokeWidth="2"
                    fill="none"
                  ></circle>
                </svg>
              </LegendLabel>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default Legend
